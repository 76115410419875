import "./GlobalFonts.css";
import "./PressMentions.css";
import PressCell from "./PressCell";

import imoreIcon from "../resources/external/imore.png";
import asiaOneIcon from "../resources/external/asiaone.png";
import class95Icon from "../resources/external/class95.jpeg";
import darkoaudioIcon from "../resources/external/darkoaudio.jpg";
import geekcultureIcon from "../resources/external/geekculture.png";
import tech360Icon from "../resources/external/tech360.jpg";
import hwzIcon from "../resources/external/hwz.png";
import appAdviceIcon from "../resources/external/appadvice.jpg";
import macstoriesIcon from "../resources/external/macstories.png";

function PressMentions() {
    function makeCells(): JSX.Element {
        return (
        <>
            <PressCell 
                description="Article: This app puts worldwide sunrise, sunset times on your iOS 14 Home screen" 
                imageURL={imoreIcon} 
                altText="Link to iMore article" 
                pageLink="https://www.imore.com/app-puts-worldwide-sunrise-sunset-times-your-ios-14-home-screen"
            />
            <PressCell 
                description="Class 95 Muttons In The Morning Interview, aired on 8 June 2021" 
                imageURL={class95Icon} 
                altText="Class 95 Radio Logo" 
            />
            <PressCell 
                description="Article: LosslessSwitcher adds bit-perfect Apple Music playback to MacOS" 
                imageURL={darkoaudioIcon} 
                altText="Link to Darko.Audio article" 
                pageLink="https://darko.audio/2022/04/losslessswitcher-adds-bit-perfect-apple-music-playback-to-macos/"
            />
            <PressCell 
                description="Article: Young Singaporean app developers talk about coding in Singapore and their wishes for Apple's WWDC 2022" 
                imageURL={asiaOneIcon} 
                altText="Link to AsiaOne Singapore article" 
                pageLink="https://www.asiaone.com/digital/young-singaporean-app-developers-talk-about-coding-singapore-and-their-wishes-apples-wwdc"
            />
            <PressCell 
                description="Article: Apple Continues Making Coding Accessible To Student Developers With Swift" 
                imageURL={geekcultureIcon} 
                altText="Link to Geek Culture article" 
                pageLink="https://geekculture.co/apple-continues-making-coding-accessible-to-student-developers-with-swift/"
            />
            <PressCell 
                description="Article: Breaking code, not tradition: This developer's journey of self-taught programming and innovative app development" 
                imageURL={hwzIcon} 
                altText="Link to SPH HardwareZone Singapore article" 
                pageLink="https://www.hardwarezone.com.sg/feature-breaking-code-not-tradition-developers-journey-self-taught-programming-and-innovative-app-de"
            />
            <PressCell 
                description="Article: Why People Should Start Checking Out Swift Playgrounds As WWDC 2022 Approaches" 
                imageURL={tech360Icon} 
                altText="Link to tech360.tv article" 
                pageLink="https://www.tech360.tv/why-you-should-check-out-swift-playgrounds-wwdc-2022"
            />

            <PressCell 
                description="Apple Watch Telegram Client Pigeon Updated With Stickers, Voice Messaging" 
                imageURL={appAdviceIcon} 
                altText="Link to AppAdvice article" 
                pageLink="https://appadvice.com/post/apple-watch-telegram-client-pigeon-updated-with-stickers-voice-messaging/777668"
            />

            <PressCell description="watchOS 11: The MacStories Review, Third Party Apps, Oxpecker" imageURL={macstoriesIcon} altText="Link to MacStories article" pageLink="https://www.macstories.net/stories/watchos-11-the-macstories-review/7/#:~:text=new%20watchOS%20APIs.-,Oxpecker,-Oxpecker%20is%20a"/>

        </>)
    }

    return (
        <>
        <div className="press-mentions pt-3 pb-8">
            <div className="max-w-6xl md:mx-auto">
                <div className="flex flex-col items-start ml-5 mr-5">
                    <div className="section-title serif-font">Press mentions</div>
                    <div className="sans-font text-left">
                        Here are some press outlets that mentioned my works:
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-9 gap-6 md:gap-8 lg:gap-5 pt-4 md:pt-5">
                        {makeCells()}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default PressMentions;