import sunlightAppIcon from "../../../resources/appicons/Sunlight.png";
import atmosphereAppIcon from "../../../resources/appicons/Atmosphere.png";
import environmentAppIcon from "../../../resources/appicons/Environment.png";
import iconicAppIcon from "../../../resources/appicons/Iconic.png";
import colourConvertAppIcon from "../../../resources/appicons/ColourConvert.png";
import avenueAppIcon from "../../../resources/appicons/Avenue.png";
import trainIslandIcon from "../../../resources/appicons/Train Island.png";
import DefaultSmsIcon from "../../../resources/appicons/DefaultSMS.png";

import "./Privacy.css";
import Footer from "../../Footer";
import { useEffect } from "react";

function Privacy(): JSX.Element {

    useEffect(() => {
        document.title = "Privacy";
    }, []);

    return (
        <>
            <span className="serif-font privacy-title">Privacy.</span>
            <div className="max-w-5xl privacy-contents mx-auto pb-32">
                <div className="p-3">
                    <img src={atmosphereAppIcon} className="mt-4 mb-2 app" alt="Atmosphere app icon"/>
                    <h2>Atmosphere</h2>
                    <p>
                        Atmosphere does not track your personal data. All processing of camera footage is done instantly on device using a local AI model.<br/>
                        Processed frames are discarded immediately.<br/>
                        On image capture, image files are stored locally on your device.
                    </p>
                </div>
                <div className="p-3">
                    <img src={sunlightAppIcon} className="mt-4 mb-2 app" alt="sunlight app icon"/>
                    <h2>Sunlight - Rise &amp; Set Times</h2>
                    <p>
                        Location data is optional. Users may choose to grant location data use, where it will only be used in the app, for the on-device calculations of relative time of day (including, but not limited to: sunrise, sunset, moonrise, moonset times) in that particular region.<br/>
                        Location data will not be used for any other purposes.<br/>
                        Finding of location for manual location adding for use of computation of times, relies on Apple APIs, hence search queries are sent to Apple's servers for the retrievals of location.<br/>
                        Sunlight does not track your location, your personal data, or usage data, in the widget and in the app.
                    </p>
                </div>
                <div className="p-3">
                    <img src={avenueAppIcon} className="mt-4 mb-2 app" alt="avenue app icon"/>
                    <h2>Avenue GPX Tracker</h2>
                    <p>
                        Avenue GPX Viewer does not track your personal data, including your usage of the app.
                        Data processing, such as the loading of your file, is done on device, and internet connection is solely used to load or cache map data.
                    </p>
                </div>
                <div className="p-3">
                    <img src={environmentAppIcon} className="mt-4 mb-2 app" alt="environment app icon"/>
                    <h2>Environment</h2>
                    <p>
                        Environment does not track your personal data, including your usage of both the iOS and watchOS app, including the widget.
                        All it does, is to query weather information using your internet connection, from Singapore weather sources such as <a href="https://weather.gov.sg">Meteorological Service Singapore</a>, <a href="https://www.nea.gov.sg">National Environmental Agency (NEA)</a> or <a href="https://data.gov.sg">data.gov.sg</a>, to provide you with the latest and most relevant weather data.
                    </p>
                </div>
                <div className="p-3">
                    <img src={iconicAppIcon} className="mt-4 mb-2 app" alt="iconic app icon"/>
                    <h2>Iconic</h2>
                    <p>
                        Iconic does not communicate to the internet, and does not require an internet connection, at all.
                        Everything done in Iconic, stays locally in your Mac.
                        No tracking too, of course.
                    </p>
                </div>
                <div className="p-3">
                    <img src={colourConvertAppIcon} className="mt-4 mb-2 app" alt="colourconvert app icon"/>
                    <h2>ColourConvert</h2>
                    <p>
                        ColourConvert does not communicate to the internet, and does not require an internet connection, at all.
                        Everything done in ColourConvert, stays locally in your Mac.
                        No tracking too, of course.
                    </p>
                </div>
                <div className="p-3">
                    <img src={trainIslandIcon} className="mt-4 mb-2 app" alt="train island app icon"/>
                    <h2>Train Island</h2>
                    <p>
                        Train Island does not track you. All data processing is done locally on your device.<br/>
                        Core functionalities does not require an internet connection either.
                    </p>
                </div>

                <div className="p-3">
                    <img src={DefaultSmsIcon} className="mt-4 mb-2 app" alt="default sms app icon"/>
                    <h2>DefaultSMS</h2>
                    <p>
                        DefaultSMS does not track you. All data processing is done locally on your device.<br/>
                    </p>
                </div>
            </div>
            <Footer backgroundColor="clear" foregroundColor="black" shouldGradient={false} />
        </>
    )
}

export default Privacy;